<div class="login-box">
    <div class="login-logo">
      <a [routerLink]="['/']">
        <!-- <img src="../../../assets/img/easyevals.png" alt="EASYEVALS ADMIN" width="30%"> -->
        <br />
        <b>PharmaZeal</b>
      </a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Forgot Password</p>
        
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="input-group mb-3">
              <input
                formControlName="email"
                type="email"
                class="form-control"
                placeholder="Email"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3" *ngIf="isEmailExist">
              <input
                formControlName="otp"
                type="number"
                class="form-control"
                placeholder="OTP"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-key"></span>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-button
                        [type]="'submit'"
                        [block]="true"
                        [text]="msg"
                        [loading]="isAuthLoading"
                        [color]="'info'"
                    ></app-button>
                </div>
            </div>
        </form>
        <p class="mb-1">
            <a [routerLink]="['/login']">I want to Sign In</a>
        </p>
        </div>
      <!-- /.login-card-body -->
    </div>
  </div>
