<div class="login-box">
    <div class="login-logo">
      <a [routerLink]="['/']">
        <!-- <img src="../../../assets/img/easyevals.png" alt="EASYEVALS ADMIN" width="30%"> -->
        <br />
        <b>PharmaZeal</b>
      </a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Reset Password</p>
  
        <form [formGroup]="resetPswForm" (ngSubmit)="resetPsw()">
          <div class="input-group mb-3">
            <input
              formControlName="email"
              type="email"
              class="form-control"
              placeholder="Email"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              placeholder="Password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              formControlName="retypePassword"
              type="password"
              class="form-control"
              placeholder="Retype Password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-button
                [type]="'submit'"
                [block]="true"
                [text]="'Reset Password'"
                [loading]="isAuthLoading"
                [color]="'info'"
              ></app-button>
            </div>
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>