<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{pageTitle}}</h1>
      </div>
      <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right" *ngIf="breadcrumbs">
              <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item {{ (breadcrumb.title === pageTitle) ? 'active' : '' }}">
                <span *ngIf="breadcrumb.title !== pageTitle">
                  <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.title }}</a>
                </span>
                <span *ngIf="breadcrumb.title === pageTitle">
                  {{ breadcrumb.title }}
                </span>
              </li>
          </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>