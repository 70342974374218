import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '../../utils/services/api.service';
//import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  // dtOptions: any = {};
  // allUser: any;
  // render: number;
  // dtTrigger: Subject<any> = new Subject<any>();
  getData: any
  addAboutUsForm: FormGroup
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public cancelCatRoute: string;
  private _userId: string = localStorage.getItem("id");
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    { name: 'links' }, { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    { name: 'styles' },
    { name: 'colors' }],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
  }
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective
  category: string;
  footer_content: any;
  content_id: string;

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }
  // @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
  // @ViewChild('importImg', { static: false }) importImg: ElementRef;
  // @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;


    ngOnInit(): void {
      this.category = this.router.url.split('/')[1];
      this.content_id = `(${this.router.url.split('/')[2]})`;  // Store ID in parentheses
    
     

    this.addAboutUsForm = new FormGroup({
      category_name: new FormControl(),
      about_us: new FormControl(),
      status: new FormControl(),
    });
    this.addAboutUsForm = this.formBuilder.group({

      // category_name: ['', [Validators.required]],    
      about_us: [[], [Validators.required]],
      status: ['', []],
    });
    this.getContent()
  }

  /*AddAboutUsContent() {
    if (this.content_id == undefined) {
      if (
        this.addAboutUsForm.value.about_us.trim() == undefined ||
        this.addAboutUsForm.value.about_us.trim() == ''
      ) {
        this.toastr.error('Please Enter Content');
        return false;
      }


      let reqparams = {
        "user_id": this._userId,
        "status": this.addAboutUsForm.get('status').value,
        "about_us": this.addAboutUsForm.get('about_us').value,
        "category_name": "About Us"
      };

      // formData.append('status', this.addAboutUsForm.get('status').value);
      // formData.append('category_name', this.addAboutUsForm.get('category_name').setValue('About Us'));
      // formData.append('footer_content', this.addAboutUsForm.get('footer_content').value);
      // formData.append('user_id', this._userId);
      this.apiService.AddFooterContent(reqparams).subscribe((result: any) => {
        if (result.success) {
          this.toastr.success(result.message);
          //this.router.navigate['/about-us-list']
          this.router.navigateByUrl('/about-us-list');
        }
        console.log(result)
      });
    }
    else {

      let footer_id = this.getData._id
      let reqpara = {
        "about_us": this.addAboutUsForm.get('about_us').value

      }
      this.apiService.editAboutUsContent(footer_id, reqpara).subscribe((result: any) => {
        if (result.success) {
          console.log(result.message)
          this.toastr.success(result.message);
          //this.router.navigate['/about-us-list']
          this.router.navigateByUrl('/about-us-list');
        }
      })

    }
  }*/
    AddAboutUsContent() {
      if (this.content_id == undefined) {
        if (
          this.addAboutUsForm.value.about_us.trim() == undefined ||
          this.addAboutUsForm.value.about_us.trim() == ''
        ) {
          this.toastr.error('Please Enter Content');
          return false;
        }
    
        let reqparams = {
          "user_id": this._userId,
          "status": this.addAboutUsForm.get('status').value,
          "about_us": this.addAboutUsForm.get('about_us').value.trim(), // Ensuring trim on about_us content
          "category_name": "About Us"
        };
    
        this.apiService.AddFooterContent(reqparams).subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.router.navigateByUrl('/about-us-list');
          }
          console.log(result);
        });
      } else {
        let footer_id = this.content_id.replace(/[()]/g, ''); // Remove parentheses if content_id is in "(123)" format
        let reqpara = {
          "about_us": this.addAboutUsForm.get('about_us').value.trim(),
          "status": this.addAboutUsForm.get('status').value // Ensure status is included
        };
    
        this.apiService.editAboutUsContent(footer_id, reqpara).subscribe((result: any) => {
          if (result.success) {
             this.toastr.success(result.message);
            this.router.navigateByUrl('/about-us-list');
          }
        });
      }
    }
    
    getContent() {
      // Pass the content_id directly as a string, not as an object
      this.apiService.getaboutUsById(this.content_id).subscribe((res: any) => {
        if (res.success) {
          this.getData = res.getData;
           // Ensuring CKEditor receives the about_us content correctly
          this.addAboutUsForm.patchValue({
            about_us: this.getData?.about_us
          });
         }
      });
    }
    
    
}
