import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
// import { ProfileComponent } from './views/profile/profile.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { NewsListComponent } from './views/news-list/news-list.component';
import { NewsComponent } from './views/news/news.component';
import { AllCategoriesComponent } from './views/all-categories/all-categories.component';
import { AdminListComponent } from './views/admin-list/admin-list.component';
import { UserListComponent } from './views/user-list/user-list.component';
import { UserLogDetailsComponent } from './views/user-log-details/user-log-details.component';
import { DashboardVideoComponent } from './views/dashboard-video/dashboard-video.component';
import { DashboardVideoListComponent } from './views/dashboard-video-list/dashboard-video-list.component';
import { DashVideoSubcategoryComponent } from './views/dash-video-subcategory/dash-video-subcategory.component';
import { DashVideoSubcategoryListComponent } from './views/dash-video-subcategorylist/dash-video-subcategorylist.component';
import { NewsSubcategoryComponent } from './views/news-subcategory/news-subcategory.component';
import { NewsSubcategoryListComponent } from './views/news-subcategory-list/news-subcategory-list.component';
import { RegisterComponent } from './pages/register/register.component'
import { GmpChangeDaysComponent } from './views/gmp-change-days/gmp-change-days.component';
import { ChangeCategoryNameComponent } from './views/change-category-name/change-category-name.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './views/terms-and-condition/terms-and-condition.component';
import { DisclaimerComponent } from './views/disclaimer/disclaimer.component';
import { AboutUsListComponent } from './views/about-us-list/about-us-list.component';
import { PrivacyPolicyListComponent } from './views/privacy-policy-list/privacy-policy-list.component';
import { TermsAndConditionListComponent } from './views/terms-and-condition-list/terms-and-condition-list.component';
import { DisclaimerListComponent } from './views/disclaimer-list/disclaimer-list.component';
import { AboutUsAddComponent } from './views/about-us-add/about-us-add.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
          ],
        },
      },
      // {
      //   path: 'profile',
      //   component: ProfileComponent,
      //   data: {
      //     title: 'Profile',
      //     breadcrumb: [
      //       {
      //         title: 'Dashboard',
      //         url: '/',
      //       },
      //       {
      //         title: 'Profile',
      //         url: '/profile',
      //       },
      //     ],
      //   },
      // },
      {
        path: 'admin-list',
        component: AdminListComponent,
        data: {
          title: 'Admin Panel',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
          ],
        },
      },
      {
        path: 'user-list',
        component: UserListComponent,
        data: {
          title: 'User Panel',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'User Panel',
              url: '/user-list',
            },
          ],
        },
      },
      {
        path: 'user-details',
        component: UserLogDetailsComponent,
        data: {
          title: 'User Login Details',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'User Login Details',
              url: '/user-details',
            },
          ],
        },
      },
      {
        path: 'admin-list/register',
        component: RegisterComponent,
        data: {
          title: 'New Admin',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
            {
              title: 'New Admin',
              url: '/admin-list/register',
            },
          ],
        },
      },
      {
        path: 'admin-list/edit-admin/:admin_id',
        component: RegisterComponent,
        data: {
          title: 'Edit Admin',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
            {
              title: 'Edit Admin',
              url: '/admin-list/edit-admin/:admin_id',
            },
          ],
        },
      },
      {
        path: 'change-category-name',
        component: ChangeCategoryNameComponent,
        data: {
          title: 'Change Category Name',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Change Category Name',
              url: '/change-category-name',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines-list',
        component: NewsListComponent,
        data: {
          title: 'Guidelines List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines List',
              url: '/regulatory-guidelines-list',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines/create-regulatory-guidelines',
        component: AllCategoriesComponent,
        data: {
          title: 'Create Guidelines',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines',
              url: '/regulatory-guidelines-list',
            },
            {
              title: 'Create Guidelines',
              url: '/regulatory-guidelines/create-regulatory-guidelines',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines/edit-regulatory-guidelines/:news_id/:news_type',
        component: AllCategoriesComponent,
        data: {
          title: 'Edit Guidelines',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines',
              url: '/regulatory-guidelines-list',
            },
            {
              title: 'Edit Guidelines',
              url: '/regulatory-guidelines/edit-regulatory-guidelines/:news_id/:news_type',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines-subcategory-list',
        component: NewsSubcategoryListComponent,
        data: {
          title: 'Guidelines Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines Sub-Category List',
              url: '/regulatory-guidelines-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines-subcategory/create-regulatory-guidelines-subcategory',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Create Guidelines Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines Sub-Category',
              url: '/regulatory-guidelines-subcategory-list',
            },
            {
              title: 'Create Guidelines Sub-Category',
              url: '/regulatory-guidelines-subcategory/create-regulatory-guidelines-subcategory',
            },
          ],
        },
      },
      {
        path: 'regulatory-guidelines-subcategory/edit-regulatory-guidelines-subcategory/:subCat_id',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Edit Guidelines Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Guidelines Sub-Category',
              url: '/regulatory-guidelines-subcategory-list',
            },
            {
              title: 'Edit Guidelines Sub-Category',
              url: '/regulatory-guidelines-subcategory/edit-regulatory-guidelines-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'business-list',
        component: NewsListComponent,
        data: {
          title: 'Business List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business List',
              url: '/business-list',
            },
          ],
        },
      },
      {
        path: 'business/create-business',
        component: NewsComponent,
        data: {
          title: 'Create Business',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business',
              url: '/business-list',
            },
            {
              title: 'Create Business',
              url: '/business/create-business',
            },
          ],
        },
      },
      {
        path: 'business/edit-business/:news_id/:news_type',
        component: NewsComponent,
        data: {
          title: 'Edit Business',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business',
              url: '/business-list',
            },
            {
              title: 'Edit Business',
              url: '/business/edit-business/:news_id/:news_type',
            },
          ],
        },
      },
      {
        path: 'business-subcategory-list',
        component: NewsSubcategoryListComponent,
        data: {
          title: 'Business Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business Sub-Category List',
              url: '/business-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'business-subcategory/create-business-subcategory',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Create Business Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business Sub-Category',
              url: '/business-subcategory-list',
            },
            {
              title: 'Create Business Sub-Category',
              url: '/business-subcategory/create-business-subcategory',
            },
          ],
        },
      },
      {
        path: 'business-subcategory/edit-business-subcategory/:subCat_id',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Edit Business Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Business Sub-Category',
              url: '/business-subcategory-list',
            },
            {
              title: 'Edit Business Sub-Category',
              url: '/business-subcategory/edit-business-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'videos-list',
        component: DashboardVideoListComponent,
        data: {
          title: 'Videos List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Videos List',
              url: '/videos-list',
            },
          ],
        },
      },
      {
        path: 'videos/add-videos',
        component: DashboardVideoComponent,
        data: {
          title: 'Add Video',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Video',
              url: '/videos/add-videos',
            },
          ],
        },
      },
      {
        path: 'videos/edit-videos/:videos_id/:video_type',
        component: DashboardVideoComponent,
        data: {
          title: 'Edit Video',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Video',
              url: '/videos-list',
            },
            {
              title: 'Edit Video',
              url: '/videos/edit-videos/:videos_id/:video_type',
            },
          ],
        },
      },
      {
        path: 'video-subcategory-list',
        component: DashVideoSubcategoryListComponent,
        data: {
          title: 'Video Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Video Sub-Category List',
              url: '/video-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'video-subcategory/create-video-subcategory',
        component: DashVideoSubcategoryComponent,
        data: {
          title: 'Create Video Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Video Sub-Category',
              url: '/video-subcategory-list',
            },
            {
              title: 'Create Video Sub-Category',
              url: '/video-subcategory/create-video-subcategory',
            },
          ],
        },
      },
      {
        path: 'video-subcategory/edit-video-subcategory/:subCat_id',
        component: DashVideoSubcategoryComponent,
        data: {
          title: 'Edit Video Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Video Sub-Category',
              url: '/video-subcategory-list',
            },
            {
              title: 'Edit Video Sub-Category',
              url: '/video-subcategory/edit-video-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'news-list',
        component: NewsListComponent,
        data: {
          title: 'News List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News List',
              url: '/news-list',
            },
          ],
        },
      },
      {
        path: 'news/create-news',
        component: NewsComponent,
        data: {
          title: 'Create News',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News',
              url: '/news-list',
            },
            {
              title: 'Create News',
              url: '/news/create-news',
            },
          ],
        },
      },
      {
        path: 'news/edit-news/:news_id/:news_type',
        component: NewsComponent,
        data: {
          title: 'Edit News',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News',
              url: '/news-list',
            },
            {
              title: 'Edit News',
              url: '/news/edit-news/:news_id/:news_type',
            },
          ],
        },
      },
      {
        path: 'news-subcategory-list',
        component: NewsSubcategoryListComponent,
        data: {
          title: 'News Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News Sub-Category List',
              url: '/news-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'news-subcategory/create-news-subcategory',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Create News Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News Sub-Category',
              url: '/news-subcategory-list',
            },
            {
              title: 'Create News Sub-Category',
              url: '/news-subcategory/create-news-subcategory',
            },
          ],
        },
      },
      {
        path: 'news-subcategory/edit-news-subcategory/:subCat_id',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Edit News Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'News Sub-Category',
              url: '/news-subcategory-list',
            },
            {
              title: 'Edit News Sub-Category',
              url: '/news-subcategory/edit-news-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'gmp-list',
        component: NewsListComponent,
        data: {
          title: 'GMP List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP List',
              url: '/gmp-list',
            },
          ],
        },
      },
      {
        path: 'gmp/create-gmp',
        component: AllCategoriesComponent,
        data: {
          title: 'Create GMP',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP',
              url: '/gmp-list',
            },
            {
              title: 'Create GMP',
              url: '/gmp/create-gmp',
            },
          ],
        },
      },
      {
        path: 'gmp/edit-gmp/:news_id/:news_type',
        component: AllCategoriesComponent,
        data: {
          title: 'Edit GMP',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP',
              url: '/gmp-list',
            },
            {
              title: 'Edit GMP',
              url: '/gmp/edit-gmp/:news_id/:news_type',
            },
          ],
        },
      },
      {
        path: 'gmp-subcategory-list',
        component: NewsSubcategoryListComponent,
        data: {
          title: 'GMP Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP Sub-Category List',
              url: '/gmp-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'gmp-subcategory/create-gmp-subcategory',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Create GMP Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP Sub-Category',
              url: '/gmp-subcategory-list',
            },
            {
              title: 'Create GMP Sub-Category',
              url: '/gmp-subcategory/create-gmp-subcategory',
            },
          ],
        },
      },
      {
        path: 'gmp-subcategory/edit-gmp-subcategory/:subCat_id',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Edit GMP Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP Sub-Category',
              url: '/gmp-subcategory-list',
            },
            {
              title: 'Edit GMP Sub-Category',
              url: '/gmp-subcategory/edit-gmp-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'gmp/gmp-membership-days',
        component: GmpChangeDaysComponent,
        data: {
          title: 'GMP Membership Days',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'GMP Membership Days',
              url: '/gmp/gmp-membership-days',
            },
          ],
        },
      },
      {
        path: 'products-services-list',
        component: NewsListComponent,
        data: {
          title: 'Products List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products List',
              url: '/products-services-list',
            },
          ],
        },
      },
      {
        path: 'products-services/create-products-services',
        component: NewsComponent,
        data: {
          title: 'Create Products',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products',
              url: '/products-services-list',
            },
            {
              title: 'Create Products',
              url: '/products-services/create-products-services',
            },
          ],
        },
      },
      {
        path: 'products-services/edit-products-services/:news_id/:news_type',
        component: NewsComponent,
        data: {
          title: 'Edit Products',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products',
              url: '/products-services-list',
            },
            {
              title: 'Edit Products',
              url: '/products-services/edit-products-services/:news_id/:news_type',
            },
          ],
        },
      },
      {
        path: 'products-services-subcategory-list',
        component: NewsSubcategoryListComponent,
        data: {
          title: 'Products Sub-Category List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products Sub-Category List',
              url: '/products-services-subcategory-list',
            },
          ],
        },
      },
      {
        path: 'products-services-subcategory/create-products-services-subcategory',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Create Products Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products Sub-Category',
              url: '/products-services-subcategory-list',
            },
            {
              title: 'Create Products Sub-Category',
              url: '/products-services-subcategory/create-products-services-subcategory',
            },
          ],
        },
      },
      {
        path: 'products-services-subcategory/edit-products-services-subcategory/:subCat_id',
        component: NewsSubcategoryComponent,
        data: {
          title: 'Edit Products Sub-Category',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Products Sub-Category',
              url: '/products-services-subcategory-list',
            },
            {
              title: 'Edit Products Sub-Category',
              url: '/products-services-subcategory/edit-products-services-subcategory/:subCat_id',
            },
          ],
        },
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
          title: 'About Us',
          breadcrumb: [
            // {
            //   title: 'Dashboard',
            //   url: '/',
            // },
            {
              title: 'About Us',
              url: '/about-us',
            },
          ],
        }
      },
      {
        path: 'edit-about-us/:about_id',
        component: AboutUsComponent,
        data: {
          title: 'Edit About Us',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'About Us',
              url: '/about-us-list',
            },
            {
              title: 'Edit About Us',
              url: '/edit-about-us/:about_id',
            },
          ],
        },
      },
      {
        path: 'edit-disclaimer/:about_id',
        component: DisclaimerComponent,
        data: {
          title: 'Edit Disclaimer',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Disclaimer',
              url: '/disclaimer-list',
            },
            {
              title: 'Edit Disclaimer',
              url: '/edit-disclaimer/:about_id',
            },
          ],
        },
      },
      {
        path: 'edit-terms-conditions/:about_id',
        component: TermsAndConditionComponent,
        data: {
          title: 'Edit Terms & Conditions',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Terms & Conditions',
              url: '/terms-and-conditions-list',
            },
            {
              title: 'Edit Terms & Conditions',
              url: '/edit-terms-conditions/:about_id',
            },
          ],
        },
      },
      {
        path: 'edit-privacy-policy/:about_id',
        component: PrivacyPolicyComponent,
        data: {
          title: 'Edit Privacy Policy',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Privacy Policy',
              url: '/privacy-policy-list',
            },
            {
              title: 'Edit Privacy Policy',
              url: '/edit-privacy-policy/:about_id',
            },
          ],
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          title: 'Privacy Policy',
          breadcrumb: [
            // {
            //   title: 'Dashboard',
            //   url: '/',
            // },
            {
              title: 'Privacy Policy',
              url: '/privacy-policy',
            },
          ],
        }
    },
      {
        path: 'terms-and-condition',
        component: TermsAndConditionComponent,
        data: {
          title: 'Terms And Conditions',
          breadcrumb: [
            // {
            //   title: 'Dashboard',
            //   url: '/',
            // },
            {
              title: 'Terms And Conditions',
              url: '/terms-and-condition',
            },
          ],
        }
    },
      {
        path: 'disclaimer',
        component: DisclaimerComponent,
        data: {
          title: 'Disclaimer',
          breadcrumb: [
            // {
            //   title: 'Dashboard',
            //   url: '/',
            // },
            {
              title: 'Disclaimer',
              url: '/disclaimer',
            },
          ],
        }
        // canActivate: [NonAuthGuard],
      },
  
  {
    path: 'about-us-list',
    component: AboutUsListComponent,
    data: {
      title: 'List',
      breadcrumb: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'List',
          url: '/about-us-list',
        },
      ],
    },
  },
  {
    path: 'about-us-add',
    component: AboutUsAddComponent,
    data: {
      title: 'Add',
      breadcrumb: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'Add',
          url: '/about-us-add',
        },
      ],
    },
  },
  {
    path: 'privacy-policy-list',
    component: PrivacyPolicyListComponent,
    data: {
      title: 'List',
      breadcrumb: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'List',
          url: '/privacy-policy-list',
        },
      ],
    },
  },
  {
    path: 'terms-and-conditions-list',
    component: TermsAndConditionListComponent,
    data: {
      title: 'List',
      breadcrumb: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'List',
          url: '/terms-and-conditions-list',
        },
      ],
    },
  },
  {
    path: 'disclaimer-list',
    component: DisclaimerListComponent,
    data: {
      title: 'List',
      breadcrumb: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'List',
          url: '/disclaimer-list',
        },
      ],
    },
  },
],
},
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'reset-password/:emailAdd',
    component: ResetPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
