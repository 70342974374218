<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <form [formGroup]="addAboutUsForm" (ngSubmit)="AddAboutUsContent()">
            <div class="card-header pb-3">
              <!-- Custom radio buttons, if needed -->
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="about_us">Content<span class="required">*</span></label>
                    <ckeditor [config]="config_ck" formControlName="about_us"></ckeditor>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <app-button 
                [type]="'submit'" 
                [block]="false" 
                [text]="'Submit'" 
                [loading]="isAuthLoading" 
                [color]="'info'" 
                [disabled]="isAuthDisabled">
              </app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/about-us-list']">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
