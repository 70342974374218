<section class="content">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover"
          >
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Content</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="allData?.length !== 0">
              <tr *ngFor="let data of allData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <div class="sn-content ellipsis" [innerHTML]="data.about_us"></div>
                </td>
                <td>
                  <div class="btn-group">
                    <a
                      type="button"
                      class="btn btn-info"
                      data-bs-toggle="tooltip"
                      title="Edit"
                      (click)="editAboutUs(data._id)"
                      [routerLink]="[editCatRoute, data._id]"
                    >
                      <i class="fas fa-pen"></i>
                    </a>
                    <button
                      type="button"
                      class="btn btn-danger"
                      data-bs-toggle="tooltip"
                      title="Delete"
                      (click)="deleteAboutUs(data._id)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="allData?.length === 0">
              <tr>
                <td colspan="3" class="no-data-available" style="text-align: center">
                  No data available!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
