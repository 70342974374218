<!--<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
           
            <form [formGroup]="addAboutUsForm" (ngSubmit)="addAboutUs()">
              <div class="card-body">
                <div class="row">
                 
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="title">Title<span class="required"></span></label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="title"
                        placeholder="Enter Title"
                      />
                    </div>
                  </div>
  
                  
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Image<span class="required"></span></label>
                      <div class="input-group">
                        <div class="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            (change)="onFileChange($event, 'img')"
                            class="custom-file-input"
                            aria-describedby="imageFile"
                            name="img"
                          />
                          <label class="custom-file-label" #importImg for="img">
                            <span>Upload Image</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
         
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="news_content">Content<span class="required"></span></label>
                      <ckeditor
                        [config]="config_ck"
                        formControlName="content"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            
  
             
              <div class="card-footer text-right">
                <app-button
                  [type]="'submit'"
                  [block]="false"
                  [text]="'Submit'"
                  [loading]="isSubmitting"
                  [color]="'info'"
                  [disabled]="addAboutUsForm.invalid"
                ></app-button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  [routerLink]="['/about-us-list']"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <form [formGroup]="addAboutUsForm" (ngSubmit)="addAboutUs()">
    <div class="form-group">
      <label for="user_id">User ID</label>
      <input
        type="text"
        id="user_id"
        formControlName="user_id"
        class="form-control"
        placeholder="Enter User ID"
      />
      <div *ngIf="addAboutUsForm.get('user_id')?.invalid && addAboutUsForm.get('user_id')?.touched">
        <small class="text-danger">User ID is required.</small>
      </div>
    </div>
  
    <div class="form-group">
      <label for="title">Title</label>
      <input
        type="text"
        id="title"
        formControlName="title"
        class="form-control"
        placeholder="Enter title"
      />
      <div *ngIf="addAboutUsForm.get('title')?.invalid && addAboutUsForm.get('title')?.touched">
        <small class="text-danger">Title is required.</small>
      </div>
    </div>
  
    <div class="form-group">
      <label for="about_us">About Us</label>
      <textarea
        id="about_us"
        formControlName="about_us"
        class="form-control"
        placeholder="Enter about us description"
      ></textarea>
      <div *ngIf="addAboutUsForm.get('about_us')?.invalid && addAboutUsForm.get('about_us')?.touched">
        <small class="text-danger">About us is required.</small>
      </div>
    </div>
  
    <div class="form-group">
      <label for="content">Content</label>
      <textarea
        id="content"
        formControlName="content"
        class="form-control"
        placeholder="Enter content"
      ></textarea>
      <div *ngIf="addAboutUsForm.get('content')?.invalid && addAboutUsForm.get('content')?.touched">
        <small class="text-danger">Content is required.</small>
      </div>
    </div>
  
    <div class="form-group">
      <label for="img">Image</label>
      <input
        type="file"
        id="img"
        (change)="onFileChange($event)"
        class="form-control"
      />
      <div *ngIf="addAboutUsForm.get('img')?.invalid && addAboutUsForm.get('img')?.touched">
        <small class="text-danger">Image is required.</small>
      </div>
    </div>
  
    <button type="submit" [disabled]="isSubmitting" class="btn btn-primary">
      Submit
    </button>
  </form>
  